<template>

 <div class="col-12"> 
 <h2>アラート設定</h2>
      
       <div v-for="(group, tag) in groupedData" :key="tag">
     <h3> {{ (tag.includes('Sensor:'))?'':tag }} </h3>

    
      <router-link :to="{ name: 'SensorAlertSetting', params: {tag: tag   , node_ids: group.map(obj => Object.values(obj)[0]) } }" >

      <div class="table-responsive">
        <table 
        style="text-align: left;
    width: 80%;
    margin-left: 10%;
    padding: 10% !important;"

        class="alert-tb table table-striped table-sm"  >
          <thead>
            <tr>
              <th class="col-6">ID</th>
              <th class="col-6">センサ名</th>
            </tr>
          </thead>
          
          <tbody >
                    
              <tr style="background:white !important;" v-for="(device, key) in group " :key="key">
                <td class="col-6" >
                
                
                 <span style=" width: 100%; display: inline-block;">  {{ device.node_id }}</span >
                </td>
                
                <td class="col-6">
                  <span style=" width: 100%; display: inline-block;">{{ device.name}}</span >
                </td>
            </tr>
          </tbody>
        </table>
   
      </div>
  
      </router-link>

      <br>
    </div>
  
</div>
</template>

<script>

import { faEye , faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import { defineComponent } from "vue";
import axios from 'axios';

export default defineComponent({
  name: "AlertSensor",
 // components:  {  } ,
  data : function() {return { 
  isSharedKeyChecked : false ,
   groupedData: {}
  } },
  computed: {},
  mounted : function(){
     this.$emit('show-header');
     //   axios.get("/api/v2/devices/?sensor_type=34"
      fetch("/api/v4/sensors", {
    method: "GET",
    credentials: "include" // Equivalent to `withCredentials: true` in axios
})
.then(response => response.json())
.then(function(json) {
    if (json.data) {
        this.devices = json.data;

        json.data.forEach(obj => {
            
            if(obj.tag.includes("Alert:") ){

              const g = (obj.tag.split("Alert:")[1].split(',')[0] );
              if (!this.groupedData[ g ]) {
                this.groupedData[ g ] = [];
              }
              this.groupedData[obj.tag].push(obj);
            }else {

              if (!this.groupedData[obj.node_id]) {
                
                this.groupedData["Sensor:" +obj.node_id] = [];
                 this.groupedData["Sensor:"+obj.node_id].push(obj);

              }
            }
        });

      
        // グループ化された結果を配列に変換
        var resultArray = Object.values(this.groupedData).map(function (group) {
            return group;
        });

        return true;
    }
    return false;
}.bind(this))
.catch(error => {
    console.error('Error fetching sensors:', error);
});    

   },
  methods: {
    }
});

</script>
